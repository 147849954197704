/* eslint-disable no-undef */
module.exports = {
  'light-blue': '#cadff5',
  blue: '#42a5f5',
  'dark-blue': '#0077c2',
  'light-red': '#ff7961',
  red: '#f44336',
  'dark-red': '#ba000d',
  white: '#fff',
  black: '#000',
  'gray-50': '#FAFAFA',
  'gray-100': '#F5F5F5',
  'gray-200': '#EEEEEE',
  'gray-300': '#E0E0E0',
  'gray-400': '#BDBDBD',
  'gray-500': '#9E9E9E',
  'gray-600': '#757575',
  'gray-700': '#616161',
  'gray-800': '#424242',
  'gray-900': '#212121',
}
